if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

const footerListToggle = document.querySelector('.footer-list-toggle');
const footerList = document.querySelector('.footer-list');
footerListToggle.addEventListener('click', function() {
    if(footerList.classList.contains('list-open')) {
        this.classList.remove('toggle--open');
        footerList.classList.remove('list-open');
    } else {
        this.classList.add('toggle--open');
        footerList.classList.add('list-open');
    }
});

// Open Facilitator List Modal
const facilitatorsListTrigger = document.querySelector('.open-facilitators-modal');
const facilitatorsList = document.querySelector('.facilitators-list');
if(facilitatorsListTrigger) {
    facilitatorsListTrigger.addEventListener('click', () => {
        if(facilitatorsList.classList.contains('facilitators__slider--open')) {
            facilitatorsListTrigger.innerHTML = rvo_obj.translations.open_list;
            facilitatorsList.classList.remove('facilitators__slider--open');
        } else {
            facilitatorsList.classList.add('facilitators__slider--open');
            facilitatorsListTrigger.innerHTML = rvo_obj.translations.close_list;
        }
    });
}

// Open Facilitator Single Modal
const facilitatorsSingleModal = document.querySelector('.facilitator-modal');
const facilitatorsModalTrigger = document.querySelectorAll('.facilitators__slider__slide');
facilitatorsModalTrigger.forEach(trigger => {
    trigger.addEventListener('click', function() {
        // Fill modal with data
        const title = document.querySelector('.single-modal-title');
        const text = document.querySelector('.single-modal-text');
        const link = document.querySelector('.single-modal-url');
        title.innerHTML = this.dataset.title;
        text.innerHTML = this.dataset.content;
        link.href = this.dataset.url;
        // Show finished modal
        facilitatorsSingleModal.classList.add('facilitators__modal--open');
    });
});

// Close modals
const closeFacilitatorsModal = document.querySelectorAll('.close-facilitators-modal');
const rvoModals = document.querySelectorAll('.rvo-modal-overlay');
closeFacilitatorsModal.forEach(trigger => {
    trigger.addEventListener('click', () => {
        rvoModals.forEach(modal => {
            if(modal.classList.contains('facilitators__modal--open')) {
                modal.classList.remove('facilitators__modal--open');
            }
        });
    });
});
