const header = document.querySelector('header');

const scrollHeader = () => {
    if(window.pageYOffset > 55 && !header.classList.contains('nav-open')) {
        header.classList.add('scrolled');
    } else {
        header.classList.remove('scrolled');
    }
};
scrollHeader();
window.addEventListener('scroll', () => {
    scrollHeader();
});

if(window.innerWidth < 769) {
    const menuOpen = document.querySelector('.show-nav');
    const menuClose = document.querySelector('.close-menu');
    menuOpen.addEventListener('click', () => {
        header.classList.add('nav-open');
        if(header.classList.contains('scrolled')) {
            header.classList.remove('scrolled');
        }
    });

    menuClose.addEventListener('click', () => {
        header.classList.remove('nav-open');
        if(window.pageYOffset > 55 && !header.classList.contains('scrolled')) {
            header.classList.add('scrolled');
        }
    });
}
